import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'

const Services = () => (
  <Layout>
    <Seo title="Hizmetler" />
    <div className="container">
      <div className="about-hero">
        <StaticImage
          src="../images/logo.svg"
          alt="BM Creative Works"
          className="menu-logo"
        />
      </div>
    </div>
    <div className="container">
      <div className="services">
        <h1>Neler Yapıyoruz?</h1>

        <p>
          Potansiyeli ifade etmekten korkmadan sınırlara meydan okuyoruz çünkü
          becerileri harekete geçiren tutkunun durdurulamaz olduğuna inanıyoruz.
          Analiz etmeyi, sorgulamayı ve spesifik noktalar üzerinde çalışmayı
          seviyoruz. Görsellik ve kullanılabilirlik açısından ilerleyerek daha
          keskin deneyimler sunabilmemizin tek yolu bu.
        </p>
      </div>
    </div>

    <div className="container">
      <div className="branding">
        <div className="grd">
          <div className="sol">
            <h3>MARKALAMA</h3>
            <h2>Kimliğiniz değerlerinizi temsil eder.</h2>
            <ul>
              <li>Ürün ve marka isimlendirme</li>
              <li>Kurumsal kimlik</li>
              <li>Marka stratejisi ve planlama</li>
              <li>Logo</li>
            </ul>
          </div>
          <div className="sag">
            <p>
              Somut değerlere dayalı güçlü bir marka kimliğine ve net bir
              misyona sahip olmak rekabette bir avantajdır. Tüketiciler, bir mal
              veya hizmeti sadece fayda için satın almakla kalmaz. Çoğu zaman
              markanın temsil ettiği ve aktarılmak istenen değerlerin
              kendilerini baştan çıkarmasına izin verir. BM Creative Works’te
              bir marka kimliği oluşturmanıza yardımcı oluyoruz. Konseptinden
              stratejisine kadar kurumsal kimliğin geliştirilmesi, kampanyaların
              hazırlanması ve zamanla büyüyebilen pozitif marka değeri
              oluşturulması için tüm ayrıntılarla ilgileniyoruz. Markanızı
              tüketicinin zihninde ve pazarda doğru bir şekilde
              konumlandırıyoruz.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="service-white">
      <div className="container">
        <h3>Nasıl yaparız?</h3>
        <div className="grd">
          <div className="sol">
            <p>
              Hedef kitlenizin vizyonunu, güçlü yönlerini ve fırsatlarını tespit
              edebilmek için kendimizi fikrinizin / şirketinizin ruhuna tamamen
              kaptırıyoruz. Bu noktadan itibaren sizi farklılaştıran ve
              unutulmaz kılan kesin kimliği yaratıyoruz.
            </p>
          </div>
          <div className="sag">
            <p>
              Projedeki hedefimizi açıkça tanımlayan bir strateji oluşturduktan
              sonra isim, logo ve görsel kimliğin oluşturulması konusunda
              birlikte çalışmaya başlayabiliriz. Daha sonra iletişim
              tekniğinizin stilini oluşturuyor, onu bir kartvizit kadar küçük
              veya bir reklam kampanyası kadar büyük bir şeye dönüştürüyoruz.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="branding">
        <div className="grd">
          <div className="sol">
            <h3>GRAFİK TASARIM</h3>
            <h2>Bir mesajı iletmek için görsel çözüm.</h2>
            <ul>
              <li>İletişim ve markanın ifade edilmesi</li>
              <li>Dijital ortamda grafik tasarım</li>
              <li>Baskı için grafik tasarım</li>
              <li>Tipografi ve kaligrafi</li>
            </ul>
          </div>
          <div className="sag">
            <p>
              Grafik tasarım mükemmel iletişim aracımızdır. Form, kompozisyon,
              renk ve tipografi aracılığıyla insanları fikirlerle buluşturmamıza
              yardımcı olur. İyi bir grafik tasarım her zaman iletişimin
              hizmetinde olmalıdır. Markanıza, hedef kitlesiyle bağlantı kurması
              için doğru ifadeyi nasıl vereceğimizi biliyoruz; Kataloğunuzdaki
              veya web sitenizdeki bilgileri daha erişilebilir ve çekici hale
              getiriyoruz. Reklam kampanyanızı görünür ve akılda kalıcı kılmak
              için düşündürücü görüntüler oluşturuyoruz. Kısacası görünürlük
              kazanmanızı, verimli iletişim kurmanızı, kendinizi rakiplerinizden
              farklılaştırmanızı ve profesyonellik göstermenizi sağlıyoruz.
              İnsanlarla fikirler arasında bağlantı kurmanıza yardımcı olan
              kavramsal bir iyileştirme süreci sağlıyoruz.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="service-white">
      <div className="container">
        <h3>Nasıl yaparız?</h3>
        <div className="grd">
          <div className="sol">
            <p>
              Bir brifing üzerinde çalışarak başlıyoruz, projenin ihtiyaçları
              hakkında konuşuyoruz ve belirli bir iş akış yönü oluşturuyoruz.
              Grafik tasarım, somut iletişimin tonunu geliştirme gücüne
              sahiptir. Biz grafik tasarımı, analizden nihai sanatın
              hazırlanmasına kadar tüm aşamaları gerçekleştirdiğimiz bir süreç
              olarak algılıyoruz. Bunu yapmak için, BM Creative Works olarak her
              türlü mesajı ve neredeyse her türlü medyayı, basılı veya dijital
              olarak oluşturmak için grafik tasarıma farklı perspektiflerden
              yaklaşan, profesyonellerden oluşan bir ekibe sahibiz.
            </p>
          </div>
          <div className="sag">
            <p>
              Hem çevrimiçi hem de basılı medya için üretim ve tasarım
              dünyasında kapsamlı deneyime sahibiz. Her projeye en uygun teknik
              ve malzemeleri biliyoruz. En iyi sonuçları elde etmek için en iyi
              tedarikçilerle çalışıyor ve iyi sonuçlara ulaşmak için üretim
              aşamasını da her zaman denetliyoruz. İşimiz, bir marka, ürün veya
              hizmet için anlam oluşturmak ve müşterilerin merakını ve
              heyecanını uyandırmaktır. Hayal gücümüzün yettiği kadar
              tasarlıyoruz. Bu konuda bir sınırımız yok.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="branding">
        <div className="grd">
          <div className="sol">
            <h3>WEB TASARIM</h3>
            <h2>
              Web sitelerinin ve çevrimiçi mağazaların tasarımı ve
              geliştirilmesi.
            </h2>
            <ul>
              <li>Kurumsal web sitelerinin tasarımı ve geliştirilmesi</li>
              <li>Çevrimiçi mağazaların tasarımı ve geliştirilmesi</li>
            </ul>
          </div>
          <div className="sag">
            <p>
              Bir web sitesi, işinizi günde 24 saat, yılda 365 gün tanıtır. Bunu
              yapabilecek başka hiçbir sistem yoktur. Web tasarım sadece güzel
              bir tasarım yapmakla ilgili değil, işinize işlevsel bir pazarlama
              stratejisi uygulamakla ilgilidir. Dijital varlığınızın merkez
              üssüdür. İyi yasarlanmış bir web sitesiyle hizmet veya ürün sunmak
              için doğru müşterileri çekecek ve zamanla kalıcı bir ilişki
              yaratacaksınız. Ziyaretçilerin aradıklarını kolayca bulmaları için
              kullanıcı odaklı çalışıyoruz.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="service-white">
      <div className="container">
        <h3>Nasıl yaparız?</h3>
        <div className="grd">
          <div className="sol">
            <p>
              Metodumuz, ölçülebilir hedefler belirlemek, kuruluşunuzun
              beklentilerini ve amacını bilmektir. Hedef kitlenizi belirliyoruz,
              ürün ve hizmetlerinizin tanıtımını en üst düzeye çıkarmak için
              uygun stratejileri öneriyoruz. Analitik aşamayı tamamladıktan
              sonra sayfanın kullanılabilirliğini destekleyen en uygun yapıyı
              planlıyoruz. Kaliteli içerik aracılığıyla ziyaretçi için en iyi
              deneyimi sağlayan tasarımı arıyoruz.
            </p>
          </div>
          <div className="sag">
            <p>
              Farklı tarayıcılarda ve cihazlarda işlevsellik testleri yapıyoruz.
              Sitenin sorunsuz çalıştığını doğrulamak için incelemelerimizi
              yapıyoruz. Bu kadar karmaşık bir yapının kullanıcının gözünde çok
              basit olması için optimal bir sistem oluşturuyoruz. Yine de bir
              web sitesinin veya online mağazanın internette olması yeterli
              olmaz. Sitenizi arama motorlarında en üst sıralarda konumlandırmak
              için çalışıyoruz. Bu da kullanıcıların ona erişmesine yardımcı
              oluyor. Organik konumlandırma veya SEO, web sitenizi hem
              kullanıcılar hem de arama motorları için daha kullanışlı hale
              getirir.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="branding">
        <div className="grd">
          <div className="sol">
            <h3>FOTOĞRAF</h3>
            <h2>Üç, iki, bir.</h2>
            <ul>
              <li>Kurumsal fotoğrafçılık</li>
              <li>Ürün ve yaşam tarzı fotoğrafçılığı</li>
              <li>Gastronomi fotoğrafçılığı</li>
              <li>Event fotoğrafçılığı</li>
              <li>Reklam fotoğrafçılığı</li>
            </ul>
          </div>
          <div className="sag">
            <p>
              Fotoğraf, görsel iletişim projelerindeki önemli araçlardan
              biridir. Bir ürün veya hizmetin değerlerini iletmenin estetik
              yoludur. Görüntünün temel olduğu ve her detayın müşterilere bir
              mesaj ilettiği bir dünyada yaşıyoruz. Fotoğrafın duyguları
              kışkırtması gerekir. Bunu yapmak için bir markayı, ürünlerini ve
              hizmetlerini derinlemesine bilmeliyiz. Etki yaratmak için nasıl
              sunacağımızı netleştirmeliyiz. İyi bir fotoğraf bir hikaye
              anlatabilmeli ve onu görenleri baştan çıkarabilmelidir.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="service-white">
      <div className="container">
        <h3>Nasıl yaparız?</h3>
        <div className="grd">
          <div className="sol">
            <p>
              Yalnızca fotoğraf çekmiyoruz. Onları sanat, kompozisyon, ışık ve
              anlatı ile sıfırdan yaratıyoruz. Bu sayede izleyiciye aktarmak
              istediğimiz mesaja tamamen hükmedebiliyoruz. BM Creative Works’te,
              bir şirketin veya kurumun kim olduğunu ve ne yaptığını yansıtmak
              için çalışıyoruz. Şirketinizin değerlerini aktaran benzersiz
              görüntüler elde ediyoruz.
            </p>
          </div>
          <div className="sag">
            <p>
              Yaratıcı, çok yönlü ve esneğiz. Her müşterinin tarzına uyum
              sağlamak, ürün veya hizmetlerinin özünü yakalamak çok önemlidir.
              Farklı şirketlerle ve farklı pazarlarda çalışıyoruz. Bu nedenle
              projelerin süreçlerini, mekanizmalarını ve ayrıntılarını
              anlayabiliyor, bunları yaratıcı ve gerçeğe sadık bir şekilde nasıl
              yansıtacağını biliyoruz. Ürün fotoğrafçılığında, ister ışıktaki
              bir değişiklik olsun, ister açıklayıcı bir jest olsun, en
              belirleyici anları öngörü ve içgüdü kullanarak yakalıyoruz.
              Fotoğraf sürekli evrim içinde bir disiplindir. Bu nedenle, BM
              Creative Works’te her zaman güncel durumdayız. Gelişen ve
              ilerleyen yeni teknikleri araştırıyor ve deniyoruz.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="branding">
        <div className="grd">
          <div className="sol">
            <h3>DİJİTAL PAZARLAMA</h3>
            <h2>Markanızı geliştirmek için dijital strateji.</h2>
            <ul>
              <li>Sosyal ağların stratejisi ve yönetimi</li>
              <li>E-mail marketing</li>
              <li>SEO and SEM</li>
              <li>
                Google, Facebook, Instagram'da çevrimiçi reklam kampanyalarının
                tasarımı ve yönetimi
              </li>
              <li>Videomarketing</li>
            </ul>
          </div>
          <div className="sag">
            <p>
              Dijital dünya hayatımızı değiştirdi. Şirketler hayatta kalmaya
              devam etmek istiyorlarsa değişen zamana uyum sağlamak zorundalar.
              Dijital pazarlama, günümüzde bir markanın veya şirketin
              başarısının anahtarıdır. Çünkü artık içeriğin çoğuna tek bir
              tıklamayla herkesin erişebildiği bir dünyada yaşıyoruz. Her
              markanın kendi hedefleri vardır ve bu nedenle her marka için özel
              bir dijital pazarlama stratejisini geliştiriyoruz. Bazı markalar
              için işe yarayan şeyin diğerleri üzerinde hiçbir etkisi
              olmayabilir. Bazı durumlarda Instagram gibi bir sosyal ağın
              kullanımını, bazılarında SEO konumlandırmasının iyileştirilmesini
              ve bazılarında bir blog aracılığıyla kaliteli içerik
              oluşturulmasını sağlıyoruz. Sosyal medya, dijital pazarlama
              araçlarından sadece biridir. Bu nedenle markalar daha da ileri
              gitmeli ve kişiselleştirilmiş sosyal ağlar aracılığıyla hedef
              kitleyle iletişim kurabilecekleri sistemler oluşturmalıdır.
              Teknolojinin yenilik yapma gücünden yararlanarak dijital
              stratejinin işinizin merkezinde yer alması için çalışıyoruz.
              Markanızın dijital dönüşümünü etkin bir şekilde hızlandırmak için
              marka, teknoloji, tasarım ve iletişimi bir araya getiriyoruz.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="service-white">
      <div className="container">
        <h3>Nasıl yaparız?</h3>
        <div className="grd">
          <div className="sol">
            <p>
              Dijital pazarlamanın başarılı olması için iyi bir strateji
              tasarlıyor, iletişim kanallarını seçiyor, özgün ve yaratıcı
              içerikler üreterek doğru kişileri etkiliyoruz. BM Creative
              Works’te içeriği yapılandırmak, yayınları en etkili gün ve
              saatlerde yayınlamak ve önemli iş tarihlerini dikkate almak için
              temel bir araç olarak editoryal takvim kullanıyoruz.
            </p>
          </div>
          <div className="sag">
            <p>
              Bir yayında grafik tasarım metin kadar önemlidir. Bu nedenle,
              diğer marka ve şirketlerden farklı, homojen ve tutarlı içerikler
              oluşturmak için marka kimliği üzerinde çalışıyoruz. Her yayında
              değer katıyor, her zaman kullanıcıları şaşırtmaya ve markamızı
              akıllarında tutmaya çalışıyoruz. İçeriği farklı dijital
              platformlara da uyarlıyoruz, böylece her cihazdan erişilebilir ve
              doğru bir şekilde görüntülenebiliyor. Aksi takdirde arama
              motorlarında kendimizi konumlandırmamız ve nihai müşterimize
              ulaşmamız daha zor olacaktır.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Services
